<template>
  <div>
    <div class="mb-5">
      <span class="text--darken-3 inputLabel">{{ label }}</span>
    </div>

    <!-- A.D. Date Picker -->
    <div v-if="type == 'AD'">
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        transition="slide-y-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            outlined
            dense
            :label="placeholder"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
      </v-menu>
    </div>

    <!-- B.S. Date Picker -->
    <div v-else>
      <Vue3NepaliDatePicker
        @change="changeNepaliEndDate"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>

<script>
import Vue3NepaliDatePicker from '@/components/Partials/Vue3NepaliDatePicker.vue';

export default {
  name: 'DatePicker',
  components: {
    Vue3NepaliDatePicker,
  },
  data() {
    return {
      date: null,
      menu2: false,
    };
  },
  props: {
    label: { type: String, default: null },
    type: { type: String, default: 'AD' },
    emitName: { type: String, default: null },
    placeholder: { type: String, default: null },
  },
  methods: {
    changeNepaliEndDate(value) {
      this.date = value;
    },
  },
  watch: {
    date: function (val) {
      this.$emit(this.emitName, val);
    },
  },
};
</script>

<style></style>
